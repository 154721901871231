import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, FormControl, TextField } from '@mui/material';
import strategyIds from '@utils/constants/strategyIds';

const StrategyIds = ({
  strategyId,
  onChangeStrategyId
}: {
  strategyId: string;
  onChangeStrategyId: (value: string) => void;
}) => {
  const getStrategyIds = () => {
    if (!strategyId) {
      return [];
    }
    return strategyId.split(',').map((id) => id.trim().toLowerCase()) || [];
  };

  return (
    <Box display="flex" margin="12px 0px 24px 0px">
      <FormControl variant="standard" fullWidth>
        <Autocomplete<string, true, false, false>
          multiple
          value={getStrategyIds()}
          onChange={(_event, newValue: string[] | null) => {
            onChangeStrategyId(newValue?.join(',') || '');
          }}
          disableCloseOnSelect
          options={
            strategyIds.map((id) => {
              return id.value;
            }) || []
          }
          getOptionLabel={(option: string) => {
            const strategy = strategyIds.find(
              (id) => id.value.trim() === option.trim()
            );
            return strategy ? strategy.label.trim() : option.trim();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Strategy Ids"
              variant="standard"
              data-lpignore="true"
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default StrategyIds;
